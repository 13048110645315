import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import "./pager.module.scss"
import headerStyles from "./pager.module.scss"
 
const PagerUslugi = ({ pageContext }) => {

  const res = useStaticQuery(graphql`
		query {
			allUslugi {
				edges {
				  node {
					slug
					id
				  }
				} 
			}
		}
  `)
  
  const { previousPagePath, nextPagePath } = pageContext;
 
  return (
    <ul className={ `uk-pagination ${headerStyles.pagerBox}` } uk-margin="true">
      {nextPagePath && (
        <span className={ `${headerStyles.pagerNext}` }><Link to={nextPagePath}>Следующая ›</Link></span>
      )}

      {previousPagePath && (
        <span className={ `${headerStyles.pagerPrevious}` }><Link to={previousPagePath}>‹ Предыдущая</Link></span>
      )}
      
    </ul>
  );
};
 
PagerUslugi.propTypes = {
  pageContext: PropTypes.object.isRequired,
};
 
export default PagerUslugi;
